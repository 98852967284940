import React, { ReactElement } from "react";
import colors from "@styles/variables/compareCRMPricesVariables.module.scss";

import Box from "../svgs/box";
import Watch from "../svgs/watch";
import Hand from "../svgs/hand";
import FeatureList from "@components/shared/featureList";

const whyUsList = [
    {
        description:
            "Facilitate the easy, smooth flow of critical information across the company or organization.",
        icon: <Hand />,
    },
    {
        description:
            "Save time by speeding up your workflow and have access to your documents at all times.",
        icon: <Watch />,
    },
    {
        description:
            "It can also help you collect, store, and organize your files and data in one place so that you can keep tracking it.",
        icon: <Box />,
    },
];

export default function WhyUs(): ReactElement {
    return (
        <FeatureList
            featureList={whyUsList}
            mainTitle="Why Do You Need Content Intelligence?"
            colors={{
                descriptionColor: colors.whyUsItemBrief,
            }}
            classes={{
                mainTitleClasses:
                    "text-center font-bold text-xl lg:text-4xl mb-14",
                innerSectionClasses: "lg:flex flex-wrap justify-center",
                oneBoxClasses: "text-center mb-12 lg:mb-20 w-full lg:w-1/3",
                iconClasses: "children:mx-auto",
                descriptionClasses:
                    "font-light text-sm text-center w-4/5 mx-auto mt-2",
            }}
        />
    );
}
