import React from "react";

function Watch() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="92"
            height="89"
            fill="none"
            viewBox="0 0 92 89"
        >
            <g clipPath="url(#clip0_299_427)">
                <path
                    fill="#FFF1E7"
                    d="M91.994 42.69c-.05-2.64-.486-5.273-.859-7.878a23.535 23.535 0 00-.733-3.426c-.683-2.282-1.696-4.48-2.656-6.646a22.346 22.346 0 00-1.34-2.56c-1.225-2.015-2.751-3.86-4.19-5.706a26.497 26.497 0 00-4.13-4.234c-1.82-1.49-3.662-2.994-5.62-4.303-2.916-1.949-6.22-3.252-9.446-4.564a35.882 35.882 0 00-6.489-1.917C54.03.96 51.473.663 48.95.352a45.979 45.979 0 00-3.322-.288 41.493 41.493 0 00-6.819.161c-3.705.423-7.507.79-10.987 2.241-2.231.93-4.545 1.796-6.637 3.043-1.49.89-2.825 1.978-4.193 3.043-2.218 1.733-4.31 3.461-6.072 5.68-2.096 2.636-4.234 5.297-5.748 8.334-1.074 2.155-1.976 4.412-2.828 6.66-.353.93-.654 1.878-.902 2.842a27.5 27.5 0 00-.58 2.918C.504 37.438.076 39.943.01 42.44c-.025.95.006 1.901.093 2.848a64.836 64.836 0 001.024 7.32 13.094 13.094 0 003.066 5.9 13.005 13.005 0 004.811 4.467c.661.428 1.359.796 2.084 1.1.231.863.51 1.711.836 2.542.887 2.247 1.726 4.64 2.992 6.726.618 1.018 1.388 1.942 2.106 2.891.717.95 1.449 2.01 2.317 2.876 1.329 1.325 2.916 2.411 4.4 3.535.427.328.872.63 1.333.906.43.252.872.483 1.325.69 1.759.81 3.548 1.598 5.364 2.275a30.714 30.714 0 006.239 1.534c3.533.519 7.053 1.075 10.629.934a35.635 35.635 0 003.157-.279c2.47-.324 4.98-.58 7.416-1.148 3.447-.804 6.744-2.269 9.94-3.757a27.962 27.962 0 005.298-3.243c1.894-1.464 3.887-2.882 5.587-4.592 1.7-1.71 3.116-3.73 4.538-5.645a21.027 21.027 0 001.772-2.816c1.147-2.212 2.078-4.564 2.933-6.901a33.842 33.842 0 001.586-6.39c.59-3.781 1.208-7.676 1.137-11.524z"
                ></path>
            </g>
            <g fill="#51378E" clipPath="url(#clip1_299_427)">
                <path d="M27.994 44.965c.046 9.525 7.263 16.995 16.078 17.895 10.22 1.044 18.295-6.312 19.643-14.98a17.794 17.794 0 00-2.23-11.954c-.073-.128-.16-.252-.227-.384-.279-.553-.232-1.073.313-1.407.594-.37 1.164-.179 1.43.416.853 1.924 1.905 3.81 2.402 5.83 2.81 11.443-5.007 22.806-16.656 24.43-11.243 1.564-21.553-6.58-22.642-17.881a19.95 19.95 0 0117.977-21.814c4.291-.424 8.279.508 12.002 2.648.128.075.259.147.38.233.503.346.739.806.412 1.373-.347.599-.866.624-1.467.357-1.43-.654-2.82-1.446-4.312-1.897-11.494-3.497-23.071 5.132-23.104 17.135z"></path>
                <path d="M31.788 44.3h1.825v1.37h-1.682c-.37 2.394 1.35 6.667 3.537 8.823l1.124-1.457c.485.537.77.855 1.073 1.195l-1.173 1.237c1.788 2.045 6.106 3.835 8.772 3.619v-1.715h1.359c.029.572.057 1.152.088 1.803 3.41-.18 6.272-1.418 8.813-3.639l-1.327-1.407c.318-.292.619-.57.962-.883l1.355 1.292c2.22-2.52 3.471-5.383 3.651-8.8l-1.829-.113V44.33h1.822c-.014-1.979-.536-3.72-1.296-5.407-.11-.243-.238-.479-.358-.715-.286-.576-.178-1.083.385-1.378.59-.315 1.146-.136 1.412.482.506 1.172 1.073 2.337 1.392 3.564 2.766 10.737-5.543 20.882-16.63 20.362-8.11-.38-14.924-7.175-15.328-15.284-.418-8.373 5.471-15.76 13.59-17.026a16.323 16.323 0 0110.223 1.654c.536.28.894.705.654 1.282-.134.317-.55.608-.893.716-.25.071-.61-.165-.895-.306-1.788-.864-3.635-1.43-5.721-1.472v1.804H45.34l-.088-1.788c-3.406.163-6.258 1.415-8.816 3.632l1.339 1.416-1.039.917-1.137-1.51c-2.366 2.681-3.617 5.544-3.812 9.027z"></path>
                <path d="M46.01 43.535c4.482-4.483 8.904-8.906 13.324-13.33l2.022-2.024c.47-.472 1.003-.715 1.561-.191.59.558.381 1.117-.127 1.625-5.31 5.307-10.62 10.611-15.93 15.914-.632.629-1.126.622-1.767-.013-1.948-1.927-3.88-3.871-5.824-5.804-.511-.51-.726-1.089-.16-1.632.568-.544 1.134-.277 1.635.227 1.53 1.546 3.072 3.079 4.613 4.615.202.209.42.398.653.613z"></path>
            </g>
            <defs>
                <clipPath id="clip0_299_427">
                    <path fill="#fff" d="M0 0H92V89H0z"></path>
                </clipPath>
                <clipPath id="clip1_299_427">
                    <path
                        fill="#fff"
                        d="M0 0H39.966V40H0z"
                        transform="translate(26 25)"
                    ></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default Watch;
