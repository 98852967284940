import React from "react";

function Box() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="92"
            height="89"
            fill="none"
            viewBox="0 0 92 89"
        >
            <g clipPath="url(#clip0_299_428)">
                <path
                    fill="#FFF1E7"
                    d="M91.994 42.69c-.05-2.64-.486-5.273-.859-7.878a23.535 23.535 0 00-.733-3.426c-.683-2.282-1.696-4.48-2.656-6.646a22.346 22.346 0 00-1.34-2.56c-1.225-2.015-2.751-3.86-4.19-5.706a26.497 26.497 0 00-4.13-4.234c-1.82-1.49-3.662-2.994-5.62-4.303-2.916-1.949-6.22-3.252-9.446-4.564a35.882 35.882 0 00-6.489-1.917C54.03.96 51.473.663 48.95.352a45.979 45.979 0 00-3.322-.288 41.493 41.493 0 00-6.819.161c-3.705.423-7.507.79-10.987 2.241-2.231.93-4.545 1.796-6.637 3.043-1.49.89-2.825 1.978-4.193 3.043-2.218 1.733-4.31 3.461-6.072 5.68-2.096 2.636-4.234 5.297-5.748 8.334-1.074 2.155-1.976 4.412-2.828 6.66-.353.93-.654 1.878-.902 2.842a27.5 27.5 0 00-.58 2.918C.504 37.438.076 39.943.01 42.44c-.025.95.006 1.901.093 2.848a64.836 64.836 0 001.024 7.32 13.094 13.094 0 003.066 5.9 13.005 13.005 0 004.811 4.467c.661.428 1.359.796 2.084 1.1.231.863.51 1.711.836 2.542.887 2.247 1.726 4.64 2.992 6.726.618 1.018 1.388 1.942 2.106 2.891.717.95 1.449 2.01 2.317 2.876 1.329 1.325 2.916 2.411 4.4 3.535.427.328.872.63 1.333.906.43.252.872.483 1.325.69 1.759.81 3.548 1.598 5.364 2.275a30.714 30.714 0 006.239 1.534c3.533.519 7.053 1.075 10.629.934a35.635 35.635 0 003.157-.279c2.47-.324 4.98-.58 7.416-1.148 3.447-.804 6.744-2.269 9.94-3.757a27.962 27.962 0 005.298-3.243c1.894-1.464 3.887-2.882 5.587-4.592 1.7-1.71 3.116-3.73 4.538-5.645a21.027 21.027 0 001.772-2.816c1.147-2.212 2.078-4.564 2.933-6.901a33.842 33.842 0 001.586-6.39c.59-3.781 1.208-7.676 1.137-11.524z"
                ></path>
            </g>
            <g fill="#51378E" clipPath="url(#clip1_299_428)">
                <path d="M41.897 64.981H26.883c-1.662 0-1.862-.185-1.864-1.78 0-9.385 0-18.77-.019-28.154a2.097 2.097 0 01.95-1.854c3.276-2.46 6.502-4.98 9.803-7.403a4.065 4.065 0 012.23-.755c9.156-.05 18.312-.033 27.45-.03 1.561 0 1.744.184 1.744 1.76 0 8.607.021 17.212-.033 25.819a3.914 3.914 0 01-.78 2.2c-2.406 3.115-4.93 6.145-7.357 9.234a2.37 2.37 0 01-2.094.982c-5.006-.038-10.011-.019-15.016-.019zM27.132 35.566v27.269h29.466V35.566H27.132zm31.596 25.02l.258.111c1.967-2.457 3.951-4.91 5.886-7.388.234-.297.274-.795.274-1.2.016-7.482.016-14.958 0-22.428 0-.278-.058-.559-.115-1.076-1.955 1.962-3.719 3.777-5.538 5.535a2.447 2.447 0 00-.789 1.948c.026 7.826.017 15.652.017 23.478l.007 1.02zM44.625 33.416h6.773c1.953 0 4.123.496 5.796-.191 1.674-.688 2.853-2.572 4.243-3.926.678-.662 1.343-1.34 2.2-2.198h-11.43a1.202 1.202 0 00-.73.26c-2.2 1.933-4.385 3.883-6.852 6.055zm3.99-6.313c-3.788 0-7.258-.018-10.728.026-.413 0-.874.285-1.226.552-2.214 1.655-4.404 3.343-6.597 5.027-.218.168-.406.376-.763.701 4.055 0 7.874 0 11.694-.011.307-.004.603-.11.843-.3 2.193-1.908 4.364-3.85 6.778-5.997v.002z"></path>
                <path d="M41.99 39.735h4.696c.704 0 1.397.103 1.432.992.038.968-.671 1.103-1.434 1.103h-9.617c-.704 0-1.408-.096-1.432-.989-.037-.97.672-1.103 1.435-1.103l4.92-.003z"></path>
            </g>
            <defs>
                <clipPath id="clip0_299_428">
                    <path fill="#fff" d="M0 0H92V89H0z"></path>
                </clipPath>
                <clipPath id="clip1_299_428">
                    <path
                        fill="#fff"
                        d="M0 0H42.182V40H0z"
                        transform="translate(25 25)"
                    ></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default Box;
